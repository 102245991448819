function scrollTotarget(target) {
  function  refreshAffix() {
    setTimeout(function () {
      $(window).trigger('scroll');
      $(window).trigger('scroll');
    }, 1000)
  }

  target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
  if (target.length) {
    if ($("body").has(".affix").length) {
      $('html, body').animate({scrollTop: target.offset().top - 9}, 500, refreshAffix);
    } else {
      $('html, body').animate({scrollTop: target.offset().top - 61}, 500, refreshAffix);
    }
    return false;
  }
}

$(document).ready(function() {
  $.ajaxSetup({ traditional: true });

  if (location.hash) {
    setTimeout(function () {
      var target = $(location.hash);
      scrollTotarget(target);
    }, 2);
  }

  $('a[href^="'+location.pathname+'#"]:not([href="#"])').click(function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      scrollTotarget(target);
    }
  });

  $('a[href^="#"]:not([href="#"])').click(function(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      scrollTotarget(target);
    }
  });

  $("input#locationSearch").on("change keyup paste click", function(evt) {
    evt.preventDefault();

    var code = (evt.keyCode ? evt.keyCode : evt.which);
    if (code == 13) { //Enter keycode
        var location = $(".location-item:visible").first();
        if (location) {
          location.find("form").submit();
        }
    }

    var value = $("input#locationSearch").val() || "";
    value = value.toLowerCase();
    if (value) {
      $(".location-item").hide();
      $(".location-item[data-location^="+value+"]").show();
    } else {
      $(".location-item").show();
    }
  });

  $(document).on('click', '.yamm .dropdown-menu', function(e) {
    e.stopPropagation()
  })
});

$(document).ready(function(){
	//Check to see if the window is top if not then display button
	$(window).scroll(function(){
		if ($(this).scrollTop() > 100) {
			$('.scrollToTop').fadeIn();
		} else {
			$('.scrollToTop').fadeOut();
		}
	});

	//Click event to scroll to top
	$('.scrollToTop').click(function(){
		$('html, body').animate({scrollTop : 0},500);
		return false;
	});
});
